.top-bar{
    height: auto;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: var(--header-color);
    border-bottom: solid 4px var(--button-color);
}

.top-bar img{
    height: 80px;
    margin: 1rem;
}

.top-bar svg{
    height: 30px;
    margin: 0.5rem;
}

.top-bar a{
    font-size: 1.4rem;
    color: #000;
    text-decoration: none;
    text-align: center;
}
.top-bar i{
    color: var(--button-color)
}
.phone a{
    margin-right: 1rem;
}
@media screen and (max-width: 1024px) {
    .top-bar svg{
        height: 1rem;
        margin: 0.25rem;
    }  

@media screen and (max-width: 768px) {
    .top-bar img{
        height: 2.1rem;
        margin: 1rem 0.25rem;
    }
    .top-bar a{
        font-size: 1rem;
    }
    .phone a{
        margin-right: 0.9rem;
    }

    }
}