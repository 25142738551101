:root{
  --header-color: rgb(255,255,255);
  --body-color:  rgb(255, 255, 255);
  --button-color: rgb(255, 145, 77);
  --altfont-color: rgb(0, 0, 0);
  --font-color: rgb(255, 255, 255) ;
  --submit-color: rgb(13 134 188);
  --background-image: url(/public/home-page-final-banner-1.webp); 
}

html{
  max-width: 100%;
  overflow-x: hidden;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
  background-image: var(--background-image);
  background-repeat: no-repeat;  
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

