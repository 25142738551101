#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body{
  background-color:var(--body-color) !important;
}
p{
  color: var(--font-color);
}
a:hover{
  color: var(--button-color) !important;
}
.App {
  text-align: center;
}
.intro{
 margin: 9rem 0;
 flex: 1;
}
input{
  width: auto;
}
label{
  padding: 1.4rem;
  line-height: 1 !important;
  vertical-align: middle;
  background-color:var(--button-color);
  border-color: rgb(255, 255, 255);
  border-radius: 5px;
  line-height: 55px;
  height: 60px;
  font-weight: 600;
  border-radius: 5px !important;
  text-transform: uppercase !important;
  margin-bottom: 0.75rem !important;
  cursor: pointer;
  width: 50%;
}
label p{
  margin: 0;
  color: rgb(0, 0, 0);
}
.form-container{
  height: 250px;
  margin-top: 0.5rem;
  width: 100%;
}

.main-content{
  margin-top:9.5rem;
  margin-bottom: 50px;
}

h1, h2, h3 ,h4 ,h5{
  color: var(--font-color);
}

button{
  vertical-align: middle;
  background-color:var(--button-color);
  color: var(--altfont-color);
  height: 60px;
  font-weight: 600;
  border-radius: 5px !important;
  border-color: var(--button-color);
  text-transform: uppercase !important;
  margin-bottom: 0.75rem !important;
  width: 50% ;
  cursor: pointer;
  box-shadow: none;
}

.back-button{
  padding: 0.85rem 1.5rem;
  vertical-align: middle;
  background-color:var(--button-color);
  height: 60px;
  font-weight: 600;
  border-radius: 5px !important;
  border-color: rgb(0, 0, 0);
  text-transform: uppercase !important;
  margin-bottom: 0.75rem !important;
  margin-left: 25% !important;
  width: 50% !important;
  cursor: pointer;
  box-shadow: none;
}

  
button:hover, button:active{
  background-color: var(--altfont-color);
  color: var(--button-color);
  border-color: var(--altfont-color);
}
@media (hover: hover) and (pointer: fine) {
  button:hover {
      background-color: var(--altfont-color);
      color: var(--button-color);
      border-color: var(--altfont-color);
  }
}

.file-upload{
  height: 0px;
}

#mobile-photo{
  display: none;
}
button.submit{
  background-color: var(--submit-color);
  color: var(--font-color);
  border-color: var(--submit-color);
  width: 60%;
}
.thank-you i{
  color: var(--button-color);
  margin: 50px 0;
}
/*signature styles*/
.signatureCanvas {
  width: 700px !important;
  height: 340px;
  background-color: rgb(255, 255, 255);
}
button.clear, button.save{
  height: 35px;
  width: 30% !important;
  margin-top: 0% !important;
}
button.close{
  height: auto !important;
  margin-bottom: -30px !important;
  z-index: 1;
  margin-left: 665px;
  width: auto !important;
  border-radius: 0 !important;
}
.signature{
  padding-top: 1rem;
}
.signature p{
  color: rgb(0, 0, 0);
  font-size: 12px;
  text-align: left;
}
.signature img{
  height: auto;
  width: 430px !important;
  margin-bottom: 0.5rem;
}
.sigpreview{
  margin-bottom: 3rem;
}
.sigpreview img{
  background-color: rgb(255, 255, 255);
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.7);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

@media screen and (max-width: 768px) {
  #mobile-photo{
    display: block;
  }
  label, button{
    width: 75% ;
  }
  .ios-click{
  z-index: 9999;
  }
  .sigpreview{
    margin-bottom: 0;
  }
  .signature img{
    width: 75% !important
  }
  .signatureCanvas{
    width: 300px !important;
  }
  button.close{
    margin-left: 264px;
  }
}
/*Footer Styles*/
.footer{
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.footer hr{
  width: 40px!important;
  height: 2px !important;
  letter-spacing: 1px;
  border-top: 1px solid #ff944c !important;
  background: #ff944c !important;
  opacity: 1 !important;

}
.footer h4{
  font-weight: 700;
  font-size: 11px;
  font-family: 'Lato';
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}
.footer h5{
  font-size: 11px;
  font-family: Lato;
  font-weight: 700!important;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 18px;
  color: rgb(255, 255, 255);
}
.socials i{
  margin-right: 24px;
}
.terms-conditions{
  padding: 20px 30px;
  line-height: 1.4;
  font-size: 14px;
  text-align: center;
  max-width: 1140px;
}
.details{
  max-width: 992px;
}
.details p{
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0;
  color: rgb(255, 255, 255);
}
.details ul{
  padding-left: 0;
  list-style-type: none;
  width: 100%;
}
.details li{
  margin-bottom: 5px;
}
.details a{
  text-decoration: none !important;
  color: var(--font-color);
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.copy-right{
  text-align: center;
  line-height: 1.4;
  font-size: 14px;
  padding: 20px 30px;
}
.copy-right p{
  margin-bottom: 10px;
}
.footer-width{
  max-width: 992px;
}
@media screen and (max-width: 575px) {
  .details li{
    margin-bottom: 0;
  }
  .socials i{
    margin-right: 0;
  }
}